import { css } from '@emotion/react';

export const ExperienceStyle = {
  root: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '1.25rem',
  }),
};
